import TemplateForm from "../components/TemplateForm";
import { Link } from "react-router-dom";

const Create = () => {
   return (
      <div>
         <TemplateForm />
      </div>

   );
}

export default Create;